import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Tietoja</h2>
        <p className="mt-5">100% kotimainen järjestelmä</p>
        <p className="mt-5">Toteuttaja <a href="https://jakuware.com">Jakuware</a></p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Tilavaraus.fi</h2>
        <p className="mt-5"><a href="mailto:info@tilavaraus.fi">info@tilavaraus.fi</a></p>
        <p className="mt-5"><a href="tel:+358443689029">puh. 044-3689029</a></p>
      </div>
    </div>
  </footer>
);

export default Footer;
