import React from 'react'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ModalDialog = (props) => {
    const [snackbarOpen, setSnackbarOpen] = React.useState(false)
    const handleClose = () => {
        props.onClose()
    }

    const handleSnackClose = () => {
        setSnackbarOpen(false)
        props.onClose()
    }
    const handleSubmit = (e) => {
        e.preventDefault()

        //console.log(e.target)

        const {name, email, message} = e.target
        // Use your API endpoint URL you copied from the previous step
        const endpoint = "https://tailvr1oeg.execute-api.eu-north-1.amazonaws.com/prod/send-email"
        // We use JSON.stringify here so the data can be sent as a string via HTTP
        const body = JSON.stringify({
            senderName: name.value,
            senderEmail: email.value,
            message: message.value
        });
        const requestOptions = {
            method: "POST",
            body
        };
        console.log(requestOptions)

        fetch(endpoint, requestOptions)
        .then((response) => {
          if (!response.ok) throw new Error("Error in fetch");
          return response.json();
        })
        .then((response) => {
            console.log('success')
            setSnackbarOpen(true)

        })
        .catch((error) => {
            console.log('error')

        })

    };

    console.log(props)
    return (
        <>
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Ota yhteyttä</DialogTitle>
    <DialogContent>
    <form id="my-form-id" onSubmit={handleSubmit}>   
    <DialogContentText>
        Ota yhteyttä tällä lomakkeella, jos kiinnostuit varausjärjestelmästä.
    </DialogContentText>
    <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Nimi"
        type="text"
        fullWidth
        required
    />
    <TextField
        margin="dense"
        id="email"
        label="Sähköpostiosoite"
        type="email"
        fullWidth
        required
    />
    <TextField
        margin="dense"
        id="message"
        label="Viesti"
        type="text"
        multiline
        rows={2}
        rowsMax={4}
        fullWidth
        required
    />
    <DialogActions>
    <Button variant="contained" onClick={handleClose} color="primary">
        Peruuta
    </Button>
    <Button variant="contained" type="submit" color="primary">
        Lähetä
    </Button>
    </DialogActions>
    </form>
    </DialogContent>
    <Snackbar open={snackbarOpen} autoHideDuration={4000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success">
        Kiitos yhteydenotostanne!
        </Alert>
    </Snackbar>
    </Dialog>
    </>
    )
}

export default ModalDialog