import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '@material-ui/core/Button';
import ModalDialog from '../dialog'

const Header = () => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    console.log(open)
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  return (
  <header className="sticky top-0 bg-white shadow" style={{zIndex:'999'}}>
    <ModalDialog open={open} onClose={handleClose} />
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        Tilavaraus.fi
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#ominaisuudet">
          Ominaisuudet
        </AnchorLink>
        <AnchorLink className="px-4" href="#tietoja">
          Monipuolinen järjestelmä
        </AnchorLink>
        <AnchorLink className="px-4" href="#hinnat">
          Hinnat
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <Button color="primary" variant="contained" onClick={handleClickOpen} className="text-sm">Tilaa tästä</Button>
      </div>
    </div>
  </header>)
}

export default Header;
